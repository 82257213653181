@include media-breakpoint-only(md) {
    #header-philosophy a {
        font-size: 2rem;
    }
    .container {
        padding: 0;

        header {
            padding: 0 .75rem;
        }

        .title-image {
            margin-bottom: 2rem;
        }

        .content {
            margin: 0 .75rem;
        }

        .card {
            min-height: 250px;

            .card-title {
                font-size: 2.2rem;
            }

            h2.card-title {
                font-size: 1.7rem;
            }
        }

        p {
            overflow-x: hidden;
        }
    }
}

@include media-breakpoint-down(md) {
    nav #navbarContent a.nav-link {
        font-size: 1.5rem;
    }
    #header-philosophy a {
        font-size: 1rem;
    }
    .philosophy {
        .card img {
            margin-top: -2rem;
            margin-left: 1rem;
        }
    }
    .container {
        padding: 0;

        header {
            padding: 0 .75rem;
        }

        .title-image {
            margin-bottom: 2rem;
        }

        .content {
            margin: 0 .75rem;

            &.trainings {
                margin-top: 3rem;
            }
        }

        .card {
            width: 100%;

            .card-body {
                padding: 0 1rem 0 1.5rem;
            }
        }

        .calendar-link {
            margin-top: 0;
        }

        p {
            overflow-x: hidden;
        }
    }
}

@include media-breakpoint-down(sm) {

}

