h6 {
    font-family: $font-family-serif;
   // text-transform: uppercase;
}

.content {
    margin: 0 8.33333%;

    &.training {
        hr {
            margin-bottom: 1rem;
            margin-top: 3rem;
        }
    }
}

.brand-logo {
    height: 80vh;
}

.title-image {
    margin-bottom: 8rem;
}

.social-icon {
    margin-top: 1rem;
    width: 30px;
    margin-right: 8px;
}

.calendar-link {
    margin-top: .75rem;

    img {
        width: 40px;
        margin-right: 8px;
    }
}

.projects {
    img {
        margin-bottom: 1.75rem;
    }

    h3 {
        min-height: 4.5rem;
    }
}

.trainings {
    margin-top: 3rem;

    .card .card-body {

        h2 {
            font-size: $h4-font-size;
            margin-top: 0;
            min-height: 3rem;
            text-align: left;
        }
    }
}

table {
    tr:nth-child(odd) {
        background: $white;
    }

    tr:nth-child(even) {
        background: $custom-lightgray;
    }

    td {
        vertical-align: top;
        padding: .3rem;
    }
}

.card {
    margin-top: 4.5rem;
    width: 90%;
    min-height: 380px;
    color: $custom-red;
    background-image: url('/img/bgCard.svg');
    background-repeat: no-repeat;
    background-size: contain;

    &.link {
        transition: transform .2s;
    }

    &.link:hover {
        transform: scale(1.02);
    }

    &.text-only {
        margin: 0;
    }

    img {
        margin-top: -4rem;
        width: 80%;
        margin-left: 2rem;
    }

    .card-title {
        font-size: 2.8rem;
        font-family: $font-family-serif;

        strong {
            font-family: $font-family-serif-bold;
        }
    }

    p.card-title {
        font-size: 1.9rem;
    }

    .card-body {

        h2 {
            margin-top: 2rem;
            text-align: center;
            font-size: $h1-font-size;
            font-weight: $font-weight-bold;
        }

        a {
            text-decoration: none;
        }
    }

    li {
        margin-bottom: 1rem;
    }
}

.slick-prev:before, .slick-next:before {
    color: $custom-lightgray;
}

#map-canvas {
    width: 100%;
    height: 500px;
    background-image: url("/img/consentGoogle.png");
    background-repeat: no-repeat;
    background-size: cover;

    div.consent-wrapper {
        background-color: rgba(255, 255, 255, .7);
        padding: 2rem;
        margin: 2rem;
    }
}

#imprint, #legal {
    h2 {
        font-family: $font-family-serif;
        font-size: $h3-font-size;
    }
}

footer {
    background-color: $custom-gray;
    background-image: url('/img/bgFooter.png');
    background-repeat: no-repeat;
    background-size: contain;
    color: $white;
    padding-top: 2rem;
    margin-top: 4rem;

    a {
        text-decoration: none;
        color: $white;

        &:hover {
            text-decoration: underline;
            color: $white;
        }
    }
}

#header-philosophy {
    a {
        font-size: $h1-font-size;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $white;
        line-height: 1.3;
        display: inline-block;
        padding: 0 .3rem;
        text-decoration: none;

        &.text-end {
            color: $custom-mediumgray;
        }
    }
}
