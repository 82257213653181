nav {
    &.navbar {
        padding-bottom: 1rem;
    }

    i, svg {
        color: $red;
    }

    .navbar-brand {
        z-index: 10;
    }

    .navbar-collapse {
        transition: 300ms ease-in-out;
        position: absolute;
        top: 0;
        background-color: $custom-lightgray;
        height: 734px;

        .row {
            margin: 190px 8.33333% 0;
        }
    }

    .navbar-toggler {
        z-index: 10;
        color: $gray-900;
        padding: 0;
        width: 30px;
        height: 30px;
        border: none;

        &:focus {
            outline: 0;
            box-shadow: none;
        }

        // x
        .icon-bar {
            transition: 300ms ease-in-out;
            background-color: $custom-red;
            position: relative;
            width: 45px;
            height: 2px;
            display: block;
        }

        .icon-bar:nth-child(1) {
            transform: rotate(45deg);
            top: 8px;
        }

        .icon-bar:nth-child(2) {
            opacity: 0;
        }

        .icon-bar:nth-child(3) {
            transform: rotate(-45deg);
            top: 5px;
        }

        &.collapsed {
            .icon-bar {
                transform: rotate(0deg);
                top: 0;
                opacity: 1;
            }

            .icon-bar + .icon-bar {
                margin-top: 5px;
            }
        }
    }

    #navbarContent {
        color: $custom-darkgray;
        z-index: 1;

        small {
            font-weight: $font-weight-bold;
        }

        a.nav-link {
            color: $custom-darkgray;
            font-size: $h2-font-size;
            padding: 0;
            font-weight: $font-weight-bolder;

            &:hover, &.active {
                color: $custom-red;
            }
        }

        .legal {
            margin-top: 2rem;

            .nav-link {
                font-weight: $font-weight-base;
            }
        }
    }
}

