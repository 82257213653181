body, html {
    height: 100%;
    position: relative;
    overflow: initial !important;
    scroll-behavior: smooth;
}

h3 {
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
}

h2 {

    font-weight: $font-weight-base;
}

.card h2.card-title {
    font-family: $font-family-base;
}

h3 {
    font-family: $font-family-serif;
    font-weight: $font-weight-base;
}

.lead {
    font-family: $font-family-serif;
    color: $custom-gray;
}

.red {
    color: $custom-red;
}

.btn-primary {
    background-color: transparent;
    color: $custom-red;
    font-weight: $font-weight-bold;
}

.required > label:after, .required > h2:after {
    content: ' *';
    color: #C3232D;
}

.min-vh-50 {
    min-height: 50vh;
}
.form-control {
    padding: .375rem .75rem;
}
.form-select {
    padding: .375rem 1.2rem .375rem .75rem
}
